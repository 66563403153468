exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-c-users-richa-documents-www-rtp-design-g-projects-manifest-your-destiny-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=C:/Users/Richa/Documents/WWW/RTPDesign-G/projects/Manifest Your destiny/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-c-users-richa-documents-www-rtp-design-g-projects-manifest-your-destiny-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-c-users-richa-documents-www-rtp-design-g-projects-nimbash-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=C:/Users/Richa/Documents/WWW/RTPDesign-G/projects/Nimbash/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-c-users-richa-documents-www-rtp-design-g-projects-nimbash-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-c-users-richa-documents-www-rtp-design-g-projects-print-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=C:/Users/Richa/Documents/WWW/RTPDesign-G/projects/Print/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-c-users-richa-documents-www-rtp-design-g-projects-print-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-c-users-richa-documents-www-rtp-design-g-projects-watermills-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=C:/Users/Richa/Documents/WWW/RTPDesign-G/projects/Watermills/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-c-users-richa-documents-www-rtp-design-g-projects-watermills-index-mdx" */)
}

